import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

let routes = [
	{
		path: '/',
		name: 'home',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
	},
	{
		path: '/:channel(channel[1-9]\\d?)',
		name: 'homechannel',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			console.log(`访问的是 /${to.params.channel}`)
			next()
		},
	},
	{
		path: '/privacy-policy',
		name: 'privacy',
		component: () => import('../pages/privacy.vue'),
	},
	{
		path: '/terms-of-service',
		name: 'terms',
		component: () => import('../pages/terms.vue'),
	},
	{
		path: '/blogs',
		name: 'blogs',
		component: () => import('../pages/blogs.vue'),
	},
	{
		path: '/blogs/9-time-management-tips-to-benefit-your-life',
		name: 'blog1',
		component: () => import('../pages/blog1.vue'),
	},
	{
		path: '/blogs/the-ultimate-uide-to-overcoming-jet-lag',
		name: 'blog2',
		component: () => import('../pages/blog2.vue'),
	},
	{
		path: '/blogs/how-to-properly-plan-your-day',
		name: 'blog3',
		component: () => import('../pages/blog3.vue'),
	},
	{
		path: '/blogs/7-daily-planning-methods',
		name: 'blog4',
		component: () => import('../pages/blog4.vue'),
	},
	{
		path: '/:channel(channel[1-9]\\d?)/:city',
		name: 'citychannel',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
		props: true,
		beforeEnter: (to, from, next) => {
			console.log(`访问的是 /${to.params.channel}/${to.params.city}`)
			next()
		},
	},
	{
		path: '/:city',
		name: 'city',
		component: () => import(/* webpackChunkName: "home" */ '../pages/home.vue'),
	},
	// 最后放置通配路由
	{
		path: '*',
		redirect: { name: 'home' },
	},
]

export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
